<template>
  <div class="login">
    <div class="d-flex justify-content-between align-items-center px-5 pt-4">
      <img
        class="img-fluid"
        :src="logoUrl"
        alt="logo-complete"
        style="height: 35px"
      />
      <ul id="dropdown2" class="dropdown-content">
        <li>
          <a @click="changeLanguage('en')">EN</a>
        </li>
        <li>
          <a @click="changeLanguage('es')">ES</a>
        </li>
        <li>
          <a @click="changeLanguage('pr')">PR</a>
        </li>
      </ul>
      <a id="trigger2" class="dropdown-trigger" href="#" data-target="dropdown2">
        {{ $t('topbar.items[0]') }}: {{ locale }}
        <i class="material-icons right">arrow_drop_down</i>
      </a>
    </div>
    <div class="container">
      <div class="row h-100">
        <div class="col l6 m6 s12 title d-flex h-100">
          <div class="my-auto">
            <h1 class="text-start">
              {{ $t('login.welcome') }} <br /> <span style="text-transform: capitalize;">{{ companyName }}</span>
            </h1>
            <p class="text-start">
              {{ $t('login.subtitle') }}
            </p>
          </div>
        </div>
        <div class="col l6 m6 s12 form d-flex h-100">
          <div class="my-auto w-100">
            <div>
              <h2 class="text-start mb-3">{{ $t('login.form.title') }}</h2>
              <form @submit.prevent="login">
                <span class="p-float-label mb-4">
                  <input class="form-control px-4" type="email" name="User" id="User" v-model="form.user" placeholder="Email" />
                  <label for="User">{{ $t('login.form.labelEmail') }}</label>
                </span>
                <span class="p-float-label mb-4">
                  <input class="form-control px-4" type="password" name="Password" id="Password" v-model="form.password" placeholder="Password" />
                  <label for="Password">{{ $t('login.form.labelPassword') }}</label>
                </span>
                <div class="form-group d-flex justify-content-start mb-5">
                  <button class="btn btn-primary py-1 px-4">{{ $t('login.form.button') }}</button>
                </div>
              </form>
            <div>
              <p class="text-start">
                <a href="javascript:;" @click="goToForgot">{{ $t('login.forgotPass') }}</a>
              </p>
              <p class="text-start mt-3 mb-0">
                {{ $t('login.signUp.text') }} <br /> <a class="text-start" href="javascript:;" @click="goToRegister">{{ $t('login.signUp.link') }}</a>
              </p>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
export default {
  name: "LoginComponent",
  data() {
    return {
      form: {
        user: "",
        password: "",
      },
    };
  },
  computed: {
    logoUrl() {
      return store.getters.getCompanyLogo;
    },
    companyName() {
      return store.getters.getCompany.Name.toLowerCase();
    },
    locale() {
      return this.$i18n.locale.toUpperCase()
    },
  },
  methods: {
    login() {
      if (this.form.user === "" || this.form.password === "") {
        return alert("error login");
      }
      console.log("login");
      this.form.user = "";
      this.form.password = "";
    },
    goToForgot() {
      console.log('forgot');
    },
    goToRegister() {
      console.log('register');
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
  },
  mounted() {
    var dropdown = document.querySelectorAll(".dropdown-trigger");
    var dropdownOptions = {
      closeOnClick: false,
      hover: true,
      coverTrigger: false,
    };
    M.Dropdown.init(dropdown, dropdownOptions);
  }
};
</script>

<style lang="scss" scoped>
.login {
  height: 100vh;
}

.container {
  height: calc(100% - 80px);
}

.title {
  h1 {
    color: #303133;
    font-size: 68px;
    font-weight: 600;
    line-height: 75px;
    span {
      color: var(--primary);
    }
  }
  p {
    color: #303133;
    font-weight: 300;
  }
}

.form {
  .p-float-label label {
    color: rgba(0,0,0,.6);
    position: absolute;
    margin-top: 0 !important;
    top: 2.5px;
    left: 0;
    z-index: 2;
    height: 100%;
    padding: 1rem 0.75rem;
    text-align: start;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out,transform .1s ease-in-out;
    font-size: initial;
  }
  .p-float-label input:focus ~ label, .p-float-label input:not(:placeholder-shown) ~ label {
    transform: scale(.85) translateY(-.5rem) translateX(.15rem);
    color: rgba(33,37,41,.65);
  }
  input {
    box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px;
    border: none !important;
    height: calc(3.5rem + 1px*2);
    min-height: calc(3.5rem + 1px*2);
    line-height: 1.25;
    display: block;
    width: 100%;
    font-weight: 400;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    padding: 1rem .75rem;
    box-sizing: border-box;
    &:focus, &:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem;
    }
  }

  a,
  h2 {
    color: var(--primary);
  }

  h2 {
    font-size: 60px;
    font-weight: 500;
  }

  label {
    color: rgba(0, 0, 0, 0.6);
  }

  p {
    color: #303133;
    font-weight: 300;
    font-size: 16px;
    a {
      font-weight: 400;
      text-decoration: underline !important;
    }
  }

  .btn {
    border-radius: 8px;
    box-shadow: none;
  }
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.text-start {
  text-align: left !important;
}

.justify-content-start {
  justify-content: start !important;
} 

.justify-content-between {
  justify-content: space-between !important;
} 

.align-items-center {
  align-items: center !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.pt-4 {
  padding-top: 1.5rem!important;
}

.px-4 {
  padding-right: 1.5rem!important;
  padding-left: 1.5rem!important;
}

.px-5 {
  padding-right: 3rem!important;
  padding-left: 3rem!important;
}

input::placeholder {
  opacity: 0;
}
</style>
